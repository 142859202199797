import React, { useState, useEffect } from 'react';
import { Card, CardContent, Box, CardHeader, IconButton, TextField, Collapse, Stack, Chip, Button, Container, Typography, LinearProgress, Badge, CircularProgress } from '@mui/material';
import { GroupAdd, ExpandMore, Edit, AccessTime, Save, Close, DragIndicator, Map as MapIcon, CheckCircle, Error, Check, Clear } from '@mui/icons-material';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, TouchSensor, useSensor, useSensors, DragEndEvent, MouseSensor } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { usePlan } from '../../hooks/PlanProvider';
import { Activity, Plan } from '../../types/interfaces';
import { CSS } from '@dnd-kit/utilities';
import PhotoGallery from '../ActivityDrawer/PhotoGallery';
import resyLogo from '../../assets/resy-logo.png';
import uberLogo from '../../assets/uber-logo.png';
import './PlanBuilder.scss';
import ResyBookingModal from '../Integrations/ResyBookingModal';

interface IntegrationStatus {
  resy?: 'pending' | 'confirmed' | 'failed';
  uber?: 'pending' | 'confirmed' | 'failed';
  friends?: string[];
}

interface PlanBuilderActivityProps {
  activity: Activity;
  onUpdateActivity: (updatedActivity: Activity) => void;
  id: string;
}

const SortablePlanBuilderActivity: React.FC<PlanBuilderActivityProps> = ({ activity, onUpdateActivity, id }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 20 : undefined,
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const [localActivity, setLocalActivity] = useState(activity);
  const [isEditing, setIsEditing] = useState(false);
  const [integrationStatus, setIntegrationStatus] = useState<IntegrationStatus>(
    activity.integrationStatus || {}
  );
  const [showResyBooking, setShowResyBooking] = useState(false);

  const handleExpandClick = () => setIsExpanded(!isExpanded);

  const handleEditClick = () => {
    setIsEditing(true);
    setIsExpanded(true);
  };

  const handleSave = () => {
    onUpdateActivity({
      ...localActivity,
      integrationStatus: integrationStatus
    });
    setIsEditing(false);
  };

  const handleCancel = () => {
    setLocalActivity(activity);
    setIntegrationStatus(activity.integrationStatus || {});
    setIsEditing(false);
  };

  const handleResyClick = async () => {
    setShowResyBooking(true);
  };

  const handleUberClick = async () => {
    try {
      setIntegrationStatus(prev => ({ ...prev, uber: 'pending' }));
      // TODO: Implement Uber integration
      setIntegrationStatus(prev => ({ ...prev, uber: 'confirmed' }));
    } catch (error) {
      setIntegrationStatus(prev => ({ ...prev, uber: 'failed' }));
      console.error('Uber integration failed:', error);
    }
  };

  const handleFriendsClick = async () => {
    try {
      // TODO: Implement friend invite flow
      const newFriends = [...(integrationStatus.friends || []), 'new-friend-id'];
      setIntegrationStatus(prev => ({ ...prev, friends: newFriends }));
    } catch (error) {
      console.error('Friend invite failed:', error);
    }
  };

  const getIntegrationIcon = (type: 'resy' | 'uber' | 'friends') => {
    const status = integrationStatus[type];
    if (status === 'confirmed') {
      return <CheckCircle color="success" />;
    } else if (status === 'pending') {
      return <CircularProgress size={24} />;
    } else if (status === 'failed') {
      return <Error color="error" />;
    }
    return null;
  };

  const handleResyBookingComplete = () => {
    setIntegrationStatus(prev => ({ ...prev, resy: 'confirmed' }));
    setShowResyBooking(false);
    onUpdateActivity({
      ...localActivity,
      integrationStatus: {
        ...integrationStatus,
        resy: 'confirmed'
      }
    });
  };

  return (
    <>
      < ResyBookingModal
        open={showResyBooking}
        onClose={() => setShowResyBooking(false)}
        activityId={activity.placeId || ''}
        venueName={activity.name || ''}
        existingTime={activity.startTime}
        partySize={2}
        lat={activity.lat}
        lng={activity.lng}
        onBookingComplete={handleResyBookingComplete}
      />
      <div ref={setNodeRef} style={style} className="sortable-activity" data-dnd-dragging={isDragging}>
        <Card className={`activity-card ${isExpanded ? 'expanded' : ''}`}>
          <CardHeader
            title={
              isEditing ? (
                <TextField fullWidth variant="standard" placeholder="Activity name"
                  value={localActivity.name}
                  onChange={(e) => setLocalActivity({ ...localActivity, name: e.target.value })}
                />
              ) : activity.name
            }
            action={
              <Stack direction="row" spacing={1}>
                <IconButton {...attributes} {...listeners} size="small" data-drag-handle><DragIndicator /></IconButton>
                <IconButton onClick={handleEditClick} size="small"><Edit /></IconButton>
                <IconButton onClick={handleExpandClick} className={`expand-more ${isExpanded ? 'expanded' : ''}`} size="small" ><ExpandMore /></IconButton>
              </Stack>
            }
          />
          <CardContent className="activity-card-content">
            <div className="photo-gallery-container">
              <PhotoGallery photos={activity.photos} classNames={['activity-card-photo']} />
            </div>
            <div className="activity-info-container">
              <div className="time-chip">
                {activity.startTime && (
                  <Chip icon={<AccessTime />} label={activity.startTime} variant="outlined" color="primary" />
                )}
              </div>
              <div className="integration-buttons">
                <IconButton className="friends-button" onClick={handleFriendsClick} disabled={!isEditing}>
                  <Badge badgeContent={integrationStatus.friends?.length || 0} color="primary" overlap="circular" ><GroupAdd /></Badge>
                </IconButton>
                <IconButton onClick={handleResyClick} disabled={!isEditing}>
                  <Badge badgeContent={getIntegrationIcon('resy')} color="primary" overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <img src={resyLogo} alt="Resy" />
                  </Badge>
                </IconButton>
                <IconButton onClick={handleUberClick} disabled={!isEditing} >
                  <Badge badgeContent={getIntegrationIcon('uber')} color="primary" overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <img src={uberLogo} alt="Uber" />
                  </Badge>
                </IconButton>
              </div>
            </div>
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              <div className="activity-card-details">
                <TextField fullWidth multiline rows={3} label="Details" variant="outlined" placeholder="Add notes, special instructions, or reminders..."
                  value={localActivity.details || ''}
                  onChange={(e) => setLocalActivity({ ...localActivity, details: e.target.value })}
                  disabled={!isEditing} />
                <TextField fullWidth label="Start Time" type="time"
                  value={localActivity.startTime || ''}
                  onChange={(e) => setLocalActivity({ ...localActivity, startTime: e.target.value })}
                  disabled={!isEditing}
                  InputLabelProps={{ shrink: true }}
                />
                {isEditing && (
                  <div className="action-buttons">
                    <Button variant="outlined" startIcon={<Close />} onClick={handleCancel}>Cancel</Button>
                    <Button variant="contained" color="primary" startIcon={<Save />} onClick={handleSave}>Save Changes</Button>
                  </div>
                )}
              </div>
              <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                <div className="activity-card-details">
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    label="Details"
                    value={localActivity.details || ''}
                    onChange={(e) => setLocalActivity({ ...localActivity, details: e.target.value })}
                    disabled={!isEditing}
                    variant="outlined"
                    placeholder="Add notes, special instructions, or reminders..."
                  />
                  <TextField
                    fullWidth
                    label="Start Time"
                    type="time"
                    value={localActivity.startTime || ''}
                    onChange={(e) => setLocalActivity({ ...localActivity, startTime: e.target.value })}
                    disabled={!isEditing}
                    InputLabelProps={{ shrink: true }}
                  />
                  {isEditing && (
                    <div className="action-buttons">
                      <Button
                        variant="outlined"
                        startIcon={<Close />}
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Save />}
                        onClick={handleSave}
                      >
                        Save Changes
                      </Button>
                    </div>
                  )}
                </div>
              </Collapse>
            </Collapse>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

interface PlanBuilderProps {
  planId?: string;
  onMapView?: () => void;
}

const PlanBuilder: React.FC<PlanBuilderProps> = ({ planId, onMapView }) => {
  const { selectedPlan, fetchUserPlans, setSelectedPlan, savePlan } = usePlan();
  const [editedPlan, setEditedPlan] = useState<Plan>(selectedPlan);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditingName, setIsEditingName] = useState(false);
  const [localPlanName, setLocalPlanName] = useState(editedPlan.name);

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 8 } }),
    useSensor(TouchSensor, { activationConstraint: { distance: 8 } }),
    useSensor(PointerSensor, { activationConstraint: { distance: 8 } }),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  // Load draft plan if planId is provided
  useEffect(() => {
    const loadDraftPlan = async () => {
      if (planId) {
        setIsLoading(true);
        try {
          const plans = await fetchUserPlans();
          const plan = plans.find(p => p.id === planId);
          if (plan) {
            setSelectedPlan(plan);
            setEditedPlan(plan);
          }
        } catch (error) {
          console.error('Error loading draft plan:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    loadDraftPlan();
  }, [planId, fetchUserPlans, setSelectedPlan]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = editedPlan.activities.findIndex(
        (activity) => activity.placeId === active.id
      );
      const newIndex = editedPlan.activities.findIndex(
        (activity) => activity.placeId === over.id
      );

      const newActivities = arrayMove(editedPlan.activities, oldIndex, newIndex);
      const updatedPlan = { ...editedPlan, activities: newActivities };
      setEditedPlan(updatedPlan);
      savePlan(updatedPlan);
    }
  };

  const handleActivityUpdate = (updatedActivity: Activity) => {
    const updatedActivities = editedPlan.activities.map(activity =>
      activity.placeId === updatedActivity.placeId ? updatedActivity : activity
    );
    const updatedPlan = { ...editedPlan, activities: updatedActivities };
    setEditedPlan(updatedPlan);
    savePlan(updatedPlan);
  };

  const handleEditNameClick = () => {
    setLocalPlanName(editedPlan.name);
    setIsEditingName(true);
  };

  const handleCancelNameEdit = () => {
    setLocalPlanName(editedPlan.name);
    setIsEditingName(false);
  };

  const handleSaveNameEdit = () => {
    const trimmedName = localPlanName.trim();
    if (trimmedName) {
      handlePlanNameUpdate(trimmedName);
    } else {
      handleCancelNameEdit();
    }
  };

  const handlePlanNameUpdate = (newName: string) => {
    const updatedPlan = { ...editedPlan, name: newName };
    setEditedPlan(updatedPlan);
    savePlan(updatedPlan);
    setIsEditingName(false);
  };

  if (isLoading) {
    return (
      <Container maxWidth="md">
        <Box sx={{ width: '100%', mt: 4 }}>
          <LinearProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" className="plan-builder">
      <Stack spacing={3}>
        <Card className="plan-header">
          <CardHeader
            title={
              isEditingName ? (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <IconButton size="small" onClick={handleCancelNameEdit}>
                    <Clear />
                  </IconButton>
                  <TextField fullWidth autoFocus variant="standard" placeholder="Enter plan name"
                    value={localPlanName}
                    onChange={(e) => setLocalPlanName(e.target.value)}
                    onKeyUp={(e) => {
                      if (e.key === 'Enter') {
                        handleSaveNameEdit();
                      } else if (e.key === 'Escape') {
                        handleCancelNameEdit();
                      }
                    }}
                    sx={{ '& input': { fontSize: '1.25rem', fontWeight: 500 } }}
                  />
                  <IconButton size="small" onClick={handleSaveNameEdit} color="primary"> <Check /></IconButton>
                </Box>
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <IconButton size="small" onClick={handleEditNameClick}><Edit fontSize="small" /></IconButton>
                  <Typography variant="h6" component="div">{editedPlan.name}</Typography>
                </Box>
              )
            }
            subheader={`${editedPlan.activities.length} activities`}
            action={onMapView && (
              <Button startIcon={<MapIcon />} variant="outlined" size="small" onClick={onMapView}>See Map</Button>
            )}
          />
        </Card>

        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd} modifiers={[restrictToVerticalAxis, restrictToParentElement]}>
          <SortableContext items={editedPlan.activities.map(activity => activity.placeId)} strategy={verticalListSortingStrategy}>
            {editedPlan.activities.map((activity: Activity) => (
              <SortablePlanBuilderActivity
                key={activity.placeId}
                id={activity.placeId}
                activity={activity}
                onUpdateActivity={handleActivityUpdate}
              />
            ))}
          </SortableContext>
        </DndContext>
      </Stack>
    </Container>
  );
};

export default PlanBuilder;
