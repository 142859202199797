import { useQuery } from '@tanstack/react-query';
import { APIClient, APIEndpoint } from '../lib/api_client';
import { ConnectionState } from 'src/types/interfaces';


export interface ResyConnectionResponse {
  connectionState: ConnectionState;
}

/**
 * Custom React Query hook for checking Resy connection status
 */
export const useResyConnection = () => {
  return useQuery({
    queryKey: ['resyConnection'],
    queryFn: async () => {
      try {
        const response = await APIClient.get<ResyConnectionResponse>(`${APIEndpoint}/services/resy/status/`);
        return response.data.connectionState;
      } catch (error) {
        return ConnectionState.Unknown;
      }
    },
    retry: true,
    staleTime: 10 * 60 * 1000,
  });
};
