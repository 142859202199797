// Try to use Google Places API types as much as possible. We perform
// some data transformation in the job that prefetches this data to
// reduce the amount and cardinality of data we need to consider, so
// make sure this stays in sync with the web API and job systems.

export type NullLatLngLiteral = google.maps.LatLngLiteral | null;

export interface CustomPlace extends google.maps.LatLngLiteral {
  name: google.maps.places.PlaceResult["name"];
  opening_hours?: google.maps.places.PlaceOpeningHours;
  reviews?: google.maps.places.PlaceReview[];
  placeId: NonNullable<google.maps.places.PlaceResult["place_id"]>;
  rating?: google.maps.places.PlaceResult["rating"];
  photos?: {
    photoReference: ReturnType<google.maps.places.PlacePhoto["getUrl"]>;
    height: google.maps.places.PlacePhoto["height"];
    width: google.maps.places.PlacePhoto["width"];
  }[];
  priceLevel?: google.maps.places.PlaceResult["price_level"];
  types?: google.maps.places.PlaceResult["types"];
  vicinity?: google.maps.places.PlaceResult["vicinity"];
  resy_id?: number;
}

export interface Activity extends CustomPlace {
  startTime?: string;
  endTime?: string;
  details?: string;
  integrationStatus?: {
    resy?: 'pending' | 'confirmed' | 'failed';
    uber?: 'pending' | 'confirmed' | 'failed';
    friends?: string[];
  };
}

export interface DraftPlan {
  name: string;
  activities: Activity[];
  isPublic: boolean;
}

export interface SavedPlan extends DraftPlan {
  readonly id: string;
  readonly created_at: string;
  readonly updated_at: string;
}

export type Plan = DraftPlan | SavedPlan;

export const isSavedPlan = (plan: Plan): plan is SavedPlan => 'id' in plan;

export const isDraftPlan = (plan: Plan): plan is DraftPlan => !('id' in plan);

export interface PathSymbol {
  icon: {
    path: google.maps.SymbolPath;
    scale: number;
    fillColor: string;
    fillOpacity: number;
    strokeColor?: string;
    strokeWeight: number;
  };
  offset: string;
  repeat?: string;
}

export enum SubscriptionTypeChoices {
  DEFAULT,
  TRIAL,
  PRO,
}

export enum ConnectionState {
  Connected = 'connected',       // User has a valid token and it's active
  Disabled = 'disabled',         // User has a valid token but it's inactive
  Disconnected = 'disconnected', // User has neither a valid token nor an active connection
  Unknown = 'unknown',           // Connection state cannot be determined
}

export interface Subscription {
  user: number;
  type: SubscriptionTypeChoices;
  description: string;
  stripeSubscriptionId: string | null;
  isActive: boolean;
  features: string[];
  createdAt: string;
  updatedAt: string;
}
