import React, { useState } from 'react';
import { closestCenter, DndContext, DragEndEvent, MouseSensor, PointerSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import './PlanStepper.scss';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  Box,
  Button
} from '@mui/material';
import { usePlan } from '../../hooks/PlanProvider';
import { motion } from 'motion/react';
import { useQueryClient } from '@tanstack/react-query';
import { Activity, isDraftPlan } from 'src/types/interfaces';
import { SortableActivity } from './SortableActivity';
import PlanStepperHeader from './PlanStepperHeader';
import { useSnackbar } from 'src/hooks/SnackbarProvider';


interface PlanStepperProps {
  onActivityClick: (activity: Activity) => void;
}

const PlanStepper: React.FC<PlanStepperProps> = ({ onActivityClick }) => {
  const queryClient = useQueryClient();
  const { selectedPlan, savePlan, setSelectedPlanDefault, setSelectedPlanActivities } = usePlan();
  const [isCollapsed, setIsCollapsed] = useState(selectedPlan.activities.length === 0);
  const [positiveActionDisabled, setPositiveActionDisabled] = useState(false);
  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 8 } }),
    useSensor(TouchSensor, { activationConstraint: { distance: 8 } }),
    useSensor(PointerSensor, { activationConstraint: { distance: 8 } }),
  )
  const { triggerSnackbar } = useSnackbar();

  const removeActivity = (activityToRemove: Activity) => {
    const newActivities = selectedPlan.activities.filter(activity => activity !== activityToRemove);
    setSelectedPlanActivities(newActivities);

    // No activities left, collapse the plan stepper
    if (newActivities.length === 0) setIsCollapsed(true);

  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = selectedPlan.activities.findIndex((activity) => activity.placeId === active?.id);
      const newIndex = selectedPlan.activities.findIndex((activity) => activity.placeId === over?.id);

      setSelectedPlanActivities(arrayMove(selectedPlan.activities, oldIndex, newIndex));
    }
  }

  const handleSave = async () => {
    try {
      setPositiveActionDisabled(true);
      await savePlan(selectedPlan);
      await queryClient.invalidateQueries({ queryKey: ['userPlans'] });
      if (isDraftPlan(selectedPlan)) {
        triggerSnackbar("New plan has been saved!");
      } else {
        triggerSnackbar("Plan has been updated!");
      }
    } catch (error) {
      console.error('Failed to save plan:', error);
      triggerSnackbar("Plan failed to save!");
    } finally {
      setTimeout(() => {
        setPositiveActionDisabled(false);
      }, 500);
    }
  };

  const handleCancel = async () => {
    if (isDraftPlan(selectedPlan)) {
      triggerSnackbar("Draft plan has been discarded.");
    } else {
      triggerSnackbar("Plan changes have been discarded.");
    }
    setSelectedPlanDefault();
    setIsCollapsed(true);
  }

  const sortableActivities = () => (
    <>{selectedPlan.activities.map((activity, index) => (
      <SortableActivity
        key={activity.placeId}
        index={index}
        activity={activity}
        onRemove={removeActivity}
        onClick={() => onActivityClick(activity)}
      />
    ))}
    </>)

  return (
    <>
      {isCollapsed ? (
        <button className="plan-stepper-button" onClick={() => setIsCollapsed(!isCollapsed)} data-testid="plan-button">
          {selectedPlan.name} ({selectedPlan.activities.length})
        </button>
      ) : (
        <div data-testid="plan-stepper">
          <motion.div
            className="plan-stepper-container"
            initial={{ opacity: 0, x: -300 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 300 }}
          >
            <PlanStepperHeader collapseHandler={() => setIsCollapsed(!isCollapsed)} />

            <Box>
              <DndContext
                onDragEnd={handleDragEnd}
                modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                collisionDetection={closestCenter}
                sensors={sensors}
              >
                <SortableContext
                  items={selectedPlan.activities.map(activity => activity.placeId)}
                  strategy={verticalListSortingStrategy}
                >
                  {sortableActivities()}
                </SortableContext>
              </DndContext>
            </Box>

            {selectedPlan.activities.length > 0 && (
              <Box className="plan-stepper-footer">
                <Button color="secondary" variant="outlined" data-testid="plan-stepper-secondary-button"
                  onClick={handleCancel}>
                  {isDraftPlan(selectedPlan) ? 'Cancel' : 'Discard'}
                </Button>
                <Button color="secondary" variant="contained" data-testid="plan-stepper-primary-button"
                  disabled={positiveActionDisabled}
                  onClick={handleSave}>
                  {isDraftPlan(selectedPlan) ? 'Save' : 'Update'}
                </Button>
              </Box>)
            }
          </motion.div>
        </div>
      )}
    </>
  );
};

export default PlanStepper;