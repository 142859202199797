import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext,
  useCallback,
} from 'react';
import { NullLatLngLiteral } from 'src/types/interfaces';
import { useSnackbar } from './SnackbarProvider';

export const DEFAULT_LOCATION: google.maps.LatLngLiteral = { lat: 40.7128, lng: -74.0060, };

interface GeolocationContextValue {
  userGeolocation: NullLatLngLiteral;
}

const GeolocationContext = createContext<GeolocationContextValue>({
  userGeolocation: null
});

interface GeolocationProviderProps {
  children: ReactNode;
}

export const GeolocationProvider: React.FC<GeolocationProviderProps> = ({ children }) => {
  const { triggerSnackbar } = useSnackbar();
  const [userGeolocation, setUserGeolocation] = useState<NullLatLngLiteral>(null);
  const startTracking = () => {
    const newWatchId = navigator.geolocation.watchPosition(
      (position) => {
        setUserGeolocation({lat: position.coords.latitude, lng: position.coords.longitude})
      },
      err => triggerSnackbar(`Error while fetch positionL: ${err.message}`, 5000),
      {
        enableHighAccuracy: false, // request high-accuracy if available
        maximumAge: 0,            // do not use a cached position
        timeout: 1000,           // 10-second timeout
      }
    );
    return newWatchId;
  };
  const [watchId, setWatchId] = useState<number | null>(startTracking());
  const stopTracking = useCallback(() => {
    if (watchId !== null) {
      navigator.geolocation.clearWatch(watchId);
      setWatchId(null);
    }
  }, [watchId]);

  // Automatically start tracking when this component mounts,
  // and stop tracking when it unmounts.
  useEffect(() => {
    startTracking();
    return () => {
      stopTracking();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value: GeolocationContextValue = { userGeolocation };

  return (
    <GeolocationContext.Provider value={value}>
      {children}
    </GeolocationContext.Provider>
  );
};

export const useGeolocation = (): GeolocationContextValue => {
  const context = useContext(GeolocationContext);
  if (context === undefined) {
    throw new Error('useGeolocation must be used within an GeolocationProvider');
  }
  return context;
};
