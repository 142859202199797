/**
 * Converts a snake_case string to camelCase.
 *
 * @param str - The snake_case string.
 * @returns The camelCase version of the string.
 */
export const toCamelCase = (str: string): string => {
  return str.replace(/_([a-z])/g, (_, letter: string) => letter.toUpperCase());
}

/**
 * Recursively converts object keys from snake_case to camelCase.
 *
 * @param data - The data to be converted (object, array, or primitive).
 * @returns The data with keys converted to camelCase.
 */
export const keysToCamel = (data: any): any => {
  if (Array.isArray(data)) {
    return data.map(item => keysToCamel(item));
  } else if (data !== null && typeof data === 'object') {
    const newObj: { [key: string]: any } = {};
    Object.keys(data).forEach(key => {
      const camelKey = toCamelCase(key);
      newObj[camelKey] = keysToCamel(data[key]);
    });
    return newObj;
  }
  return data;
}

/**
 * Converts a camelCase string to snake_case.
 * @param str - The camelCase string.
 * @returns The snake_case string.
 */
export const camelToSnake = (str: string): string => {
  return str.replace(/([A-Z])/g, (match) => `_${match.toLowerCase()}`);
};

type AnyObject = { [key: string]: any };

/**
 * Recursively transforms the keys of an object or array from camelCase to snake_case.
 * @param data - The object, array, or value to transform.
 * @returns The transformed object, array, or value.
 */
export const keysToSnakeCase = (data: any): any => {
  if (Array.isArray(data)) {
    return data.map(item => keysToSnakeCase(item));
  } else if (data !== null && typeof data === 'object') {
    return Object.keys(data).reduce((acc: AnyObject, key: string) => {
      const newKey = camelToSnake(key);
      acc[newKey] = keysToSnakeCase(data[key]);
      return acc;
    }, {});
  }
  return data;
};

/**
 * Validates if the given string is a valid email address.
 */
export const isValidEmail = (email: string) => {
  const emailRegex = new RegExp("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$");
  return emailRegex.test(email);
}

/**
 * Validates if a password meets the specified criteria.
 */
export const isValidPassword = (password: string) => {
  const passwordRegex = new RegExp("^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$");
  return passwordRegex.test(password);
}

/**
 * Validates if the given string is a valid phone number.
 */
export const isValidPhone = (phone: string): boolean => {
  // The regex below Matches:
  //   15109437012
  //   5109437012
  //   (510) 943-7012
  //   (510)943-7012
  //   +15109437012       NOTE: This is the format that Resy anticipates
  //   +1 510 943 7012
  //   +12 (510) 943-7012
  // etc...
  const phoneRegex = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  return phoneRegex.test(phone);
}

/**
 * Validates if a given string is a valid SMS code.
 */
export const isValidSMSCode = (code: string) => {
  const codeRegex = /^\d{3}-?\d{3}$/
  return codeRegex.test(code);
}

/**
 * Normalizes a phone number by removing formatting characters and adding a default country code if necessary.
 */
export const normalizePhone = (phone: string, defaultCountryCode: string = "+1"): string | null => {
  const cleanedNumber = phone.replace(/[\s\-\\(\\).]/g, "");

  if (!isValidPhone(cleanedNumber)) return null;
  if (phone.startsWith("+") && phone.length >= 11) return phone;

  return `${defaultCountryCode}${cleanedNumber}`;
}

export const setLocalStorageItem = <T>(key: string, value: T): void => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error(`Error setting localStorage key "${key}":`, error);
  }
};

export const getLocalStorageItem = <T>(key: string): T | null => {
  try {
    const item = localStorage.getItem(key);
    if (!item) {
      return null;
    }
    return JSON.parse(item) as T;
  } catch (error) {
    console.error(`Error parsing localStorage key "${key}":`, error);
    return null;
  }
};

export const removeLocalStorageItem = (key: string): void => localStorage.removeItem(key);

