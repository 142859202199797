import React, { createContext, useContext, useState, useCallback } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { APIClient } from '../lib/api_client';
import { Activity, DraftPlan, isSavedPlan, Plan, SavedPlan } from '../types/interfaces';
import { getLocalStorageItem, removeLocalStorageItem } from 'src/lib/utils';
import { AuthContext } from './AuthProvider';


const DEFAULT_PLAN: DraftPlan = {
  name: 'Plan',
  activities: [],
  isPublic: false,
}

interface PlanContextType {
  bookResyReservation: (venueId: string, time: string, partySize: number) => Promise<void>;
  deletePlan: (plan: SavedPlan) => Promise<void>;
  fetchUserPlans: () => Promise<SavedPlan[]>;
  findResyVenue: (query: string) => Promise<any>;
  isLoading: boolean;
  savePlan: (plan: Plan) => Promise<void>;
  selectedPlan: Plan;
  setSelectedPlanActivities: (activities: Activity[]) => void;
  setSelectedPlan: (plan: Plan) => void;
  userPlans: SavedPlan[];
  setSelectedPlanDefault: () => void;
}

// const PlanContext = createContext<PlanContextType | undefined>(undefined);
const PlanContext = createContext<PlanContextType>({
  bookResyReservation: (): Promise<void> => Promise.resolve(),
  deletePlan: (): Promise<void> => Promise.resolve(),
  fetchUserPlans: (): Promise<SavedPlan[]> => Promise.resolve([]),
  findResyVenue: (): Promise<any> => Promise.resolve([]),
  isLoading: false,
  savePlan: (): Promise<void> => Promise.resolve(),
  selectedPlan: DEFAULT_PLAN,
  setSelectedPlanActivities: (): void => { },
  setSelectedPlan: (): void => { },
  userPlans: [],
  setSelectedPlanDefault: (): void => { }
});

const savePlan = async (plan: DraftPlan | SavedPlan): Promise<SavedPlan> => {
  if (isSavedPlan(plan)) {
    const response = await APIClient.patch(`/plans/${plan.id}/`, plan);
    return response.data;
  } else {
    const response = await APIClient.post('/plans/', plan);
    return response.data;
  }
};

export const PlanProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const queryClient = useQueryClient();
  const auth = useContext(AuthContext);
  const [selectedPlan, setSelectedPlanValue] = useState<Plan>(getLocalStorageItem<Plan>('selectedPlan') || DEFAULT_PLAN);
  const [userPlans, setUserPlans] = useState<SavedPlan[]>([]);

  const setSelectedPlanActivities = (activities: Activity[]) => {
    if (!selectedPlan) return;

    const newPlan = { ...selectedPlan, activities };
    setSelectedPlanValue(newPlan);
    localStorage.setItem('selectedPlan', JSON.stringify(newPlan));
  };

  const setSelectedPlan = useCallback((plan: Plan) => {
    setSelectedPlanValue(plan);
    localStorage.setItem('selectedPlan', JSON.stringify(plan));
  }, []);

  const fetchUserPlans = async (): Promise<SavedPlan[]> => {
    const response = await APIClient.get('/plans/');
    setUserPlans(response.data.results);
    return response.data.results;
  };

  const userPlansQuery = useQuery<SavedPlan[]>({
    queryKey: ['userPlans'],
    queryFn: fetchUserPlans,
    enabled: !!auth.isAuthenticated,
    staleTime: 1 * 60 * 1000, // 1 minute
  });

  const { mutateAsync: savePlanMutation } = useMutation({
    mutationFn: savePlan,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userPlans'] });
    },
    onError: (error) => {
      console.error('Failed to save plan:', error);
    }
  });

  const setSelectedPlanDefault = () => {
    removeLocalStorageItem('selectedPlan');
    setSelectedPlan(DEFAULT_PLAN);
  };

  const handleSavePlan = useCallback(async (plan: Plan) => {
    try {
      const savedPlan = await savePlanMutation(plan);
      setSelectedPlan(savedPlan);
    } catch (error) {
      console.error('Error saving plan:', error);
    }
  }, [savePlanMutation, setSelectedPlan]);

  const deletePlan = async (plan: SavedPlan) => {
    try {
      await APIClient.delete(`/plans/${plan.id}/`);
      queryClient.invalidateQueries({ queryKey: ['userPlans'] });
      if ((selectedPlan as SavedPlan).id === plan.id) {
        removeLocalStorageItem('selectedPlan');
        setSelectedPlan(DEFAULT_PLAN);
      }
    } catch (error) {
      console.error('Error deleting plan:', error);
    }
  };

  const bookResyReservation = useCallback(async (venueId: string, time: string, partySize: number) => {
    try {
      await APIClient.post('/resy/book', { venue_id: venueId, time, party_size: partySize });
    } catch (error) {
      console.error('Error booking reservation:', error);
    }
  }, []);

  const findResyVenue = useCallback(async (query: string) => {
    try {
      const response = await APIClient.get(`/resy/search?query=${encodeURIComponent(query)}`);
      return response.data;
    } catch (error) {
      console.error('Error finding venue:', error);
      return [];
    }
  }, []);

  return (
    <PlanContext.Provider
      value={{
        bookResyReservation,
        deletePlan,
        fetchUserPlans,
        findResyVenue,
        isLoading: userPlansQuery.isLoading ?? false,
        savePlan: handleSavePlan,
        selectedPlan,
        setSelectedPlanActivities,
        setSelectedPlan,
        setSelectedPlanDefault,
        userPlans
      }}
    >
      {children}
    </PlanContext.Provider>
  );
};

export const usePlan = () => {
  const context = useContext(PlanContext);
  if (!context) {
    throw new Error('usePlan must be used within a PlanProvider');
  }
  return context;
};