import { Alert, AlertColor, Snackbar } from "@mui/material"
import React, { useContext } from "react";
import { useState } from "react";

interface AppSnackbarContextType {
  triggerSnackbar: (message: string, duration?: number, severity?: AlertColor) => void;
}

interface AppSnackbarProps {
  children: React.ReactNode;
}

export const AppSnackbarContext = React.createContext<AppSnackbarContextType>({
  triggerSnackbar: () => { },
});

export const AppSnackbarProvider: React.FC<AppSnackbarProps> = ({ children }) => {
  const DEFAULT_DURATION_MS = 3000;

  const [open, setSnackbarOpen] = useState(false);
  const [duration, setDuration] = useState(DEFAULT_DURATION_MS);
  const [severity, setSeverity] = useState<AlertColor>("info");
  const [message, setSnackbarMessage] = useState('');
  const onCloseHandler = () => {
    setSnackbarOpen(false);
    setSnackbarMessage('');
    setDuration(DEFAULT_DURATION_MS);
  }

  const triggerSnackbar = (message: string, duration: number = DEFAULT_DURATION_MS, severity: AlertColor = "info") => {
    setDuration(duration);
    setSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  }

  return (
    <AppSnackbarContext.Provider value={{
      triggerSnackbar,
    }}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={duration}
        onClose={() => onCloseHandler()}
        message={message}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{ marginBottom: 'var(--mobile-bottom-spacing)' }}
      >
        <Alert severity={severity} variant="outlined" sx={{ backgroundColor: 'black', color: 'white' }} >
          {message}
        </Alert>
      </Snackbar>
    </AppSnackbarContext.Provider>
  )
}

export const useSnackbar = () => {
  const context = useContext(AppSnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within an AppSnackbarProvider');
  }
  return context;
};
