import React, { memo } from 'react';
import { MarkerF } from '@react-google-maps/api';
import { CustomPlace } from '../../types/interfaces';


interface MapMarkerProps {
  customPlace: CustomPlace;
  isUser?: boolean;
  onClick?: () => void;
}

const getIconUrl = (marker: CustomPlace): string => {
  // We could use types, but they need to fit within those types allowable in this big list:
  // https://developers.google.com/maps/documentation/places/web-service/supported_types
  // Note that types are not mutually exclusive. A place may have both food and bar types.
  //
  // We'll do price range for now: Free, Inexpensive, Moderate, Expensive, Very Expensive
  // let logo_path = `/logo-red.png`;
  // let colors = ['green', 'yellow', 'magenta', 'purple', 'red']
  // if (marker.priceLevel) logo_path = `/logo-${colors[marker.priceLevel]}.png`;

  return '/marker.png'
}

const MarkerIcon = (isUser: boolean) => {
  const svg = `
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="${isUser ? '#00FFFF' : 'rgba(0, 255, 255, 0.2)'}" />
        <circle cx="12" cy="12" r="6" fill="#00FFFF" />
      </svg>
  `;
  return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svg)}`;
}

export const MapMarker: React.FC<MapMarkerProps> = ({ customPlace, isUser, onClick }) => {
  return (
    <MarkerF
      position={{ lat: customPlace.lat, lng: customPlace.lng }}
      onClick={onClick}
      title={customPlace.name}
      icon={{
        url: MarkerIcon(isUser || false),
        scaledSize: new google.maps.Size(24, 24),
        anchor: new google.maps.Point(12, 12),
      }}
    />
  );
};

export const MemoizedMapMarker = memo(({ place, onClick }: { place: CustomPlace; onClick: () => void }) => (
  <MarkerF
    key={place.placeId}
    position={{ lat: place.lat, lng: place.lng }}
    onClick={onClick}
    title={place.name}
    icon={{ url: getIconUrl(place), scaledSize: new google.maps.Size(24, 24) }}
  />
));
