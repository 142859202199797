import { FC, useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  LinearProgress,
  TextField,
  Grid,
  IconButton,
  Fade
} from '@mui/material';
import { APIClient, APIEndpoint } from '../../lib/api_client';
import { useResyConnection } from '../../hooks/useResyConnection';
import ResyModal from './ResyModal';
import './ResyBookingModal.scss';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ConnectionState } from 'src/types/interfaces';

interface ResyBookingModalProps {
  open: boolean;
  onClose: () => void;
  activityId?: string;
  venueName: string;
  existingTime?: string;
  partySize?: number;
  lat: number;
  lng: number;
  onBookingComplete?: () => void;
}

const STEPS = ['Select Time', 'Available Reservations', 'Confirm Booking'];

const ResyBookingModal: FC<ResyBookingModalProps> = ({
  open,
  onClose,
  activityId,
  venueName,
  existingTime,
  partySize = 2,
  lat,
  lng,
  onBookingComplete
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedTime, setSelectedTime] = useState<string>(existingTime || '');
  const [availableReservations, setAvailableReservations] = useState<any[]>([]);
  const [selectedReservation, setSelectedReservation] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [bookingComplete, setBookingComplete] = useState(false);
  const [showResyConnect, setShowResyConnect] = useState(false);
  const { data: isResyConnected = false } = useResyConnection();

  useEffect(() => {
    if (!isResyConnected && open) {
      setShowResyConnect(true);
    }
  }, [isResyConnected, open]);

  const handleNext = async () => {
    if (activeStep === 0 && selectedTime) {
      setLoading(true);
      try {
        // Get today's date in YYYY-MM-DD format
        const today = new Date().toISOString().split('T')[0];
        const response = await APIClient.get(`${APIEndpoint}/services/resy/venues/`, {
          params: {
            venue_name: venueName,
            lat,
            lng,
            party_size: partySize,
            date: today
          }
        });
        setAvailableReservations(response.data);
        setActiveStep(1);
      } catch (error) {
        console.error('Error fetching reservations:', error);
      } finally {
        setLoading(false);
      }
    } else if (activeStep === 1 && selectedReservation) {
      setLoading(true);
      try {
        const today = new Date().toISOString().split('T')[0];
        await APIClient.post(`${APIEndpoint}/services/resy/book/`, {
          venue_id: selectedReservation.id,
          config_id: selectedReservation.config_id,
          party_size: partySize,
          date: today,
          time: selectedReservation.time
        });
        setBookingComplete(true);
        setActiveStep(2);
        onBookingComplete?.();
      } catch (error) {
        console.error('Error booking reservation:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleClose = () => {
    setActiveStep(0);
    setSelectedTime('');
    setAvailableReservations([]);
    setSelectedReservation(null);
    setBookingComplete(false);
    onClose();
  };
  const { data: resyState = ConnectionState.Unknown } = useResyConnection();
  const progress = ((activeStep + 1) / STEPS.length) * 100;

  return (
    <>
      <ResyModal
        open={showResyConnect}
        connectionState={resyState}
        onClose={() => setShowResyConnect(false)}
      />
      <Modal open={open && !showResyConnect} onClose={handleClose} className="resy-booking-modal">
        <Card className="resy-booking-card">
          <Box className="progress-container">
            <LinearProgress
              variant="determinate"
              value={progress}
              className="glowing-progress"
            />
          </Box>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {STEPS[activeStep]}
            </Typography>
            {activeStep === 0 && (
              <TextField
                label="Select Time"
                type="time"
                value={selectedTime}
                onChange={(e) => setSelectedTime(e.target.value)}
                className="time-picker"
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            )}
            {activeStep === 1 && (
              <Box className="reservations-grid">
                {availableReservations.map((reservation) => (
                  <Button
                    key={`${reservation.id}-${reservation.time}`}
                    variant={selectedReservation?.id === reservation.id ? "contained" : "outlined"}
                    onClick={() => setSelectedReservation(reservation)}
                    className="reservation-button"
                  >
                    {reservation.time}
                  </Button>
                ))}
                {availableReservations.length === 0 && (
                  <Typography color="text.secondary">
                    No reservations available. Try adjusting your time.
                  </Typography>
                )}
              </Box>
            )}
            {activeStep === 2 && (
              <Box className="booking-complete">
                <Fade in={bookingComplete}>
                  <CheckCircleIcon color="success" className="success-icon" />
                </Fade>
                <Typography variant="h6" gutterBottom>
                  Reservation Confirmed!
                </Typography>
                <Typography color="text.secondary">
                  Your reservation at {venueName} has been booked.
                </Typography>
              </Box>
            )}
          </CardContent>
          <CardActions className="modal-actions">
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item>
                {activeStep > 0 && (
                  <IconButton onClick={handleBack} disabled={loading}>
                    <ArrowBackIcon />
                  </IconButton>
                )}
              </Grid>
              <Grid item>
                {activeStep < 2 && (
                  <IconButton
                    onClick={handleNext}
                    disabled={
                      loading ||
                      (activeStep === 0 && !selectedTime) ||
                      (activeStep === 1 && !selectedReservation)
                    }
                    color="primary"
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Modal>
    </>
  );
};

export default ResyBookingModal; 