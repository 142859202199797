import React, { useState } from 'react';
import { Button, ButtonProps } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { styled } from '@mui/material/styles';

// Extend ButtonProps to include our custom props
interface LoadingButtonProps extends Omit<ButtonProps, 'onClick'> {
  onClick: () => void;
  minDuration?: number;
}

// Styled components
const ButtonContainer = styled('div')({
  position: 'relative',
  display: 'inline-block',
});

const LoadingOverlay = styled(motion.div)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.2)',
  transformOrigin: 'left',
});

export const LoadingButton: React.FC<LoadingButtonProps> = ({
  onClick,
  children,
  minDuration = 1000,
  ...buttonProps
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    if (isLoading) return;

    setIsLoading(true);
    try {
      await Promise.all([
        onClick(),
        new Promise(resolve => setTimeout(resolve, minDuration))
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ButtonContainer>
      <Button
        {...buttonProps}
        onClick={handleClick}
        disabled={isLoading || buttonProps.disabled}
      >
        <AnimatePresence>
          {isLoading && (
            <LoadingOverlay
              initial={{ scaleX: 0 }}
              animate={{
                scaleX: [0, 0.85, 1],
                transition: {
                  duration: minDuration / 1000,
                  times: [0, 0.8, 1],
                  ease: [0.34, 1.56, 0.64, 1], // Custom spring-like easing
                }
              }}
              exit={{
                scaleX: 1,
                opacity: 0,
                transition: { duration: 0.2 }
              }}
            />
          )}
        </AnimatePresence>
        <span style={{ position: 'relative', zIndex: 1 }}>{children}</span>
      </Button>
    </ButtonContainer>
  );
};
