import React, { useState, useEffect, useContext } from 'react';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import { Dashboard, Map, Lightbulb, Build } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import './BottomNav.scss';
import { AuthContext } from '../../hooks/AuthProvider';

/**
 * A functional React component that renders a bottom navigation bar.
 * It uses React Router for navigation and responds to authentication state changes.
 */
const BottomNav: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(0);
  const authContext = useContext(AuthContext);
  const [visible, setVisible] = useState(authContext.isAuthenticated)

  useEffect(() => {
    const pathToIndex: { [key: string]: number } = {
      '/': 0,
      '/map': 1,
      '/builder': 2,
      '/inspiration': 3,
    };
    setValue(pathToIndex[location.pathname] || 0);
  }, [location]);

  useEffect(() => {
    setVisible(authContext.isAuthenticated);
  }, [authContext])

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    const indexToPath = ['/', '/map', '/builder', '/inspiration'];
    navigate(indexToPath[newValue]);
  };

  return (
    visible ?
      <Paper className="bottom-nav" elevation={3}>
        <BottomNavigation value={value} onChange={handleChange} showLabels>
          <BottomNavigationAction label="Dashboard" data-testid="dashboard-nav-action" icon={<Dashboard />} />
          <BottomNavigationAction label="Map" data-testid="map-nav-action" icon={<Map />} />
          <BottomNavigationAction label="Builder" data-testid="plan-builder-nav-action" icon={<Build />} />
          <BottomNavigationAction label="Inspiration" data-testid="inspiration-nav-action" icon={<Lightbulb />} />
        </BottomNavigation>
      </Paper> : <></>
  );
}

export default BottomNav;
