import React, { useState } from 'react';
import { IconButton, Typography } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { CustomPlace } from '../../types/interfaces';
import './PhotoGallery.scss';

interface PhotoGalleryProps {
  photos: CustomPlace["photos"];
  classNames?: string[];
}

/**
 * Generates a URL for retrieving a photo from Google Maps API
 */
const getPhotoUrl = (photoReference: string): string => {
  // Generic placeholder image URL for posterity:
  // 'https://placehold.co/160x30?text=(No+preview+available)&font=roboto'
  if (!photoReference) return '/placeholder.jpg';
  return `https://maps.googleapis.com/maps/api/place/photo?maxwidth=600&photoreference=${photoReference}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
};

/**
 * A React functional component that renders a photo gallery with navigation controls.
 */
const PhotoGallery: React.FC<PhotoGalleryProps> = ({ photos, classNames }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  if (!photos || photos.length === 0) {
    return (
      <div className="photo-gallery">
        <div className="gallery-container">
          <img
            src="/placeholder.jpg"
            alt="No photos available"
            className="gallery-image"
          />
        </div>
      </div>
    );
  }

  const handlePrevious = () => {
    setCurrentIndex((prev) => (prev - 1 + photos.length) % photos.length);
  };

  const handleNext = () => {
    setCurrentIndex((prev) => (prev + 1) % photos.length);
  };

  return (
    <div data-testid="photo-gallery" className={`photo-gallery ${classNames?.join(' ') ?? ''}`}>
      <div className="gallery-container">
        <img
          src={getPhotoUrl(photos[currentIndex].photoReference)}
          alt={`Place ${currentIndex + 1}`}
          className="gallery-image"
        />

        <div className="gallery-nav prev">
          <IconButton
            onClick={handlePrevious}
            disabled={photos.length <= 1}
            size="small"
          >
            <ArrowBackIosNewIcon />
          </IconButton>
        </div>

        <div className="gallery-nav next">
          <IconButton
            onClick={handleNext}
            disabled={photos.length <= 1}
            size="small"
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </div>

        <Typography className="photo-counter">
          {currentIndex + 1} / {photos.length}
        </Typography>

        <div className="photo-dots">
          {photos.map((_photo, index: number) => (
            <div
              key={index}
              className={`dot ${index === currentIndex ? 'active' : ''}`}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PhotoGallery;
