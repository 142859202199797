import React from 'react';
import { Container, Typography, Card, CardContent, CardActions, Button } from '@mui/material';
import './Settings.scss';
import { useSnackbar } from 'src/hooks/SnackbarProvider';
import { useAuth } from 'src/hooks/AuthProvider';

const Settings: React.FC = () => {
  const { subscriptions } = useAuth();
  const { triggerSnackbar } = useSnackbar();

  return (
    <Container maxWidth="md" className="settings-container">
      <div className="settings-header">
        <Typography variant="h4" component="h1" gutterBottom>
          User Settings
        </Typography>
        <Typography variant="subtitle1" className="settings-subtitle">
          Manage your subscriptions and account preferences below.
        </Typography>
      </div>

      <Card className="subscription-card">
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Subscription Details
          </Typography>
          {subscriptions ? (
            <div className="subscription-info">
              <Typography variant="body1">
                <strong>Current Subscription:</strong> {subscriptions.find((sub) => sub.isActive === true)?.type}
              </Typography>
              <Typography variant="body2" className="subscription-details">
                {subscriptions.find((sub) => sub.isActive === true)?.description}
              </Typography>
            </div>
          ) : (
            <Typography variant="body1">No subscription information available.</Typography>
          )}
        </CardContent>
        <CardActions>
          <Button variant="contained" color="primary" onClick={() => triggerSnackbar("This button doesn't do anything yet!")} className="manage-button">
            'Manage Subscription'
          </Button>
        </CardActions>
      </Card>

      <Card className="account-card">
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Account Settings
          </Typography>
          <Typography variant="body2" className="account-details">
            Update your account information such as email, password, and notification preferences.
          </Typography>
          {/* Future form controls can be added here */}
        </CardContent>
        <CardActions>
          <Button variant="outlined" color="primary" className="edit-account-button">
            Edit Account Settings
          </Button>
        </CardActions>
      </Card>

    </Container>
  );
};

export default Settings;
