import { useAuth } from './hooks/AuthProvider';
import { Navigate, Route, Routes } from 'react-router-dom';
import { CircularProgress, CssBaseline } from '@mui/material';
import { Register } from './components/Register/Register';
import React, { Suspense } from 'react';
import SubscriptionOnboardingDialog from './components/SubscriptionOnboarding/SubscriptionOnboardingDialog';

import BottomNav from './components/BottomNav/BottomNav';
import Dashboard from './components/Dashboard/Dashboard';
import Header from './components/Header/Header';
import Inspiration from './components/Inspiration/Inspiration';
import Integrations from './components/Integrations/integrations';
import Login from './components/Login/Login';
import Map from './components/Map/Map';
import Settings from './components/Settings/Settings';
import PlanBuilder from './components/PlanBuilder/PlanBuilder';

import './App.scss';

interface ProtectedComponentProps { component: React.ComponentType }

const ProtectedComponent: React.FC<ProtectedComponentProps> = ({ component: Component }) => {
  const { isLoading, isAuthenticated } = useAuth();

  if (isLoading) return <CircularProgress />;
  return isAuthenticated ? <Component /> : <Navigate to="/login" replace />
}

const App: React.FC = () => {
  const auth = useAuth()

  return (<>
    <CssBaseline />
    <div className="app-container">
      <Header />
      <main className="main-content">
        <Suspense fallback={<CircularProgress />}>
          <Routes>
            <Route path="/" element={<ProtectedComponent component={Dashboard} />} />
            <Route path="/login" element={auth.isAuthenticated ? <Navigate to="/" replace /> : <Login />} />
            <Route path="/register" element={auth.isAuthenticated ? <Navigate to="/" replace /> : <Register />} />
            <Route path="/map" element={<ProtectedComponent component={Map} />} />
            <Route path="/inspiration" element={<ProtectedComponent component={Inspiration} />} />
            <Route path="/builder" element={<ProtectedComponent component={PlanBuilder} />} />
            <Route path="/settings" element={<ProtectedComponent component={Settings} />} />
            <Route path="/integrations" element={<ProtectedComponent component={Integrations} />} />
          </Routes>
        </Suspense>
      </main>
      <BottomNav />
      <SubscriptionOnboardingDialog />
    </div>
  </>);
}
export default App;
