import React, { useEffect, useMemo, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Box,
  CircularProgress
} from '@mui/material';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import './SubscriptionOnboardingDialog.scss';
import { useAuth } from 'src/hooks/AuthProvider';
import { SubscriptionTypeChoices } from 'src/types/interfaces';

const SubscriptionOnboardingDialog: React.FC = () => {
  const { isAuthenticated, subscriptions } = useAuth();
  const shouldOpen = useMemo(() => {
    return isAuthenticated && !subscriptions?.some((sub) => (sub.type === SubscriptionTypeChoices.DEFAULT));
  }, [isAuthenticated, subscriptions]);

  // Only show if they're authenticated and have a default subscription
  const [isOpen, setIsOpen] = useState(shouldOpen);
  const stripe = useStripe();
  const elements = useElements();
  const [step, setStep] = useState<'select' | 'monthlyForm'>('select');
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setIsOpen(shouldOpen);
  }, [shouldOpen]);

  const handleFreeTrial = async () => {
    setIsProcessing(true);
    setError(null);
    try {
      // Call API endpoint to start free trial subscription
      await axios.post('/api/subscribe/free-trial');
      setIsOpen(false);
    } catch (err) {
      setError('Error initiating free trial. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  // Handler for monthly subscription payment
  const handleMonthlySubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!stripe || !elements) return;
    setIsProcessing(true);
    setError(null);
    try {
      // Call API to create a PaymentIntent and retrieve the client secret
      const paymentIntentRes = await axios.post('/api/create-payment-intent', { subscription: 'monthly' });
      const clientSecret = paymentIntentRes.data.clientSecret;
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        throw new Error('Payment information not found.');
      }

      // Confirm the card payment using Stripe
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: { card: cardElement }
      });

      if (result.error) {
        setError(result.error.message || 'Payment failed. Please try again.');
      } else {
        if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
          setIsOpen(false);
        } else {
          setError('Payment did not succeed, please try again.');
        }
      }
    } catch (err: any) {
      setError('Error processing payment. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  // Render the initial selection options
  const renderSelectOptions = () => (
    <Box>
      <Typography variant="body1">
        To use the app and save your plans, please subscribe to our premium features.
        Choose one of the options below:
      </Typography>
      <Box className="optionsContainer">
        <Button variant="outlined" onClick={handleFreeTrial} disabled={isProcessing}>
          {isProcessing ? <CircularProgress size={24} /> : '7-Day Free Trial'}
        </Button>
      </Box>
      <Button variant="contained" color="primary" onClick={() => setStep('monthlyForm')} disabled={isProcessing}>
        Monthly Subscription ($4.99/month)
      </Button>
    </Box>
  );

  // Render the monthly subscription payment form
  const renderMonthlyForm = () => (
    <Box component="form" onSubmit={handleMonthlySubmit} noValidate>
      <Typography variant="body1" gutterBottom>
        Please enter your payment details:
      </Typography>
      <Box className="paymentContainer">
        <CardElement />
      </Box>
      {error && <Typography className="errorMessage">{error}</Typography>}
      <Box className="formActions">
        <Button variant="text" onClick={() => setStep('select')} disabled={isProcessing}>
          Back
        </Button>
        <Button type="submit" variant="contained" color="primary" disabled={isProcessing || !stripe}>
          {isProcessing ? <CircularProgress size={24} /> : 'Subscribe'}
        </Button>
      </Box>
    </Box>
  );

  return process.env.REACT_APP_ENABLE_SUBSCRIPTION_DIALOG === "true" ? (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)} fullWidth maxWidth="sm">
      <DialogTitle>Subscription Required</DialogTitle>
      <DialogContent className="dialogContent">
        <DialogContentText>
          To use the app and save your plans, you must subscribe.
        </DialogContentText>
        {step === 'select' ? renderSelectOptions() : renderMonthlyForm()}
        {error && <Typography className="errorMessage">{error}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsOpen(false)} disabled={isProcessing} data-testid="sub-later-button">
          Later
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};

export default SubscriptionOnboardingDialog;