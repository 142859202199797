import React, { useEffect, useState } from 'react';
import { Alert } from '@mui/material';
import { Activity } from '../../types/interfaces';
import { Box, Button, SwipeableDrawer, Typography } from '@mui/material';
import { usePlan } from '../../hooks/PlanProvider';
import './ActivityDrawer.scss';
import PhotoGallery from './PhotoGallery';
import { motion } from 'framer-motion';
import resyLogo from '../../assets/resy-logo.png';

interface ActivityDrawerProps {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  selectedActivity: Activity;
}

const ActivityDrawer: React.FC<ActivityDrawerProps> = ({ open, onClose, onOpen, selectedActivity }) => {
  const { selectedPlan, setSelectedPlanActivities } = usePlan();
  const [actionButtonDisabled, setActionButtonDisabled] = useState(true)
  const [actionButtonText, setActionButtonText] = useState("")

  const handleAddToPlan = () => {
    const existingActivityIndex = selectedPlan.activities.findIndex(({ placeId }) => placeId === selectedActivity.placeId)
    if (existingActivityIndex !== -1) {
      const newActivities = [...selectedPlan.activities]
      newActivities.splice(existingActivityIndex, 1)
      setSelectedPlanActivities(newActivities)
    } else {
      const newActivity: Activity = selectedActivity;
      setSelectedPlanActivities([...selectedPlan.activities, newActivity]);
    }
    setActionButtonDisabled(false)
    onClose();
  };

  useEffect(() => {
    const existingActivityIndex = selectedPlan.activities.findIndex(({ placeId }) => placeId === selectedActivity?.placeId)
    if (existingActivityIndex !== -1) {
      setActionButtonText("Remove from plan")
      setActionButtonDisabled(false)
    } else {
      if (selectedPlan.activities.length > 2) {
        setActionButtonText("Plan is full!")
        setActionButtonDisabled(true)
      } else {
        setActionButtonText("Add to plan")
        setActionButtonDisabled(false)
      }
    }
  }, [selectedPlan, selectedActivity])

  if (!selectedActivity) return null;

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      classes={{ paper: 'activity-drawer-paper' }}
      aria-labelledby="activity-drawer-title"
      data-testid="activity-drawer"
    >
      <motion.div
        initial={{ y: 300 }}
        animate={{ y: 0 }}
        exit={{ y: 300 }}
        transition={{ duration: 0.5 }}
      >
        <Box
          className="activity-drawer-container"
          role="dialog"
          aria-modal="true"
        >
          <PhotoGallery photos={selectedActivity.photos} />

          <Typography
            variant="h5"
            gutterBottom
            id="activity-drawer-title"
          >
            {selectedActivity.name || 'No Info Available'}
          </Typography>

          <Typography variant="body1" color="text.secondary">{selectedActivity.vicinity || 'No Address Available'}</Typography>
          {selectedActivity.priceLevel && (
            <Typography variant="body2" color="text.secondary">
              Price Level: {'💰'.repeat(selectedActivity.priceLevel)}
            </Typography>
          )}
          {/* {selectedActivity.resy_id && (
            <img src={resyLogo} alt="resy-logo" style={{ width: 40, height: 40, marginRight: 16 }} />
          )} */}
          {selectedActivity.rating && (
            <Typography variant="body2" color="text.secondary">
              Rating: {selectedActivity.rating}★ ({selectedActivity.reviews?.length || 0} reviews)
            </Typography>
          )}
          {selectedActivity.opening_hours && (
            <div className="opening-hours">
              <Typography variant="subtitle1">
                {selectedActivity.opening_hours.open_now ? 'Open Now' : 'Closed Now'}
              </Typography>
              {selectedActivity.opening_hours.weekday_text && (
                <>
                  <Typography variant="body2">
                    <strong>Opening Hours:</strong>
                  </Typography>
                  <ul>
                    {selectedActivity.opening_hours.weekday_text.map((day: string, index: number) => (
                      <li key={index}>{day}</li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          )}
          {selectedActivity.reviews && selectedActivity.reviews.length > 0 && (
            <div className="user-reviews">
              <Typography variant="subtitle1">User Reviews</Typography>
              {selectedActivity.reviews.map((review, index: number) => (
                <Box key={index} className="review">
                  <Typography variant="body2">
                    <strong>{review.author_name}</strong> - {review.rating}★
                  </Typography>
                  <Typography variant="body2">{review.text}</Typography>
                </Box>
              ))}
            </div>
          )}
          <br />
          {selectedPlan.activities.length > 2 && (
            <Alert sx={{ color: '#0288d1', padding: '0px 16px' }} severity="info">Max of 3 activities reached.</Alert>
          )}
          <Box className="activity-drawer-actions">
            <Button
              variant="outlined"
              color="secondary"
              data-testid="activity-drawer-secondary-button"
              onClick={() => window.open(`https://www.google.com/maps/dir/?api=1&destination=${selectedActivity.lat},${selectedActivity.lng}`, '_blank')}
              aria-label={`Get directions to ${selectedActivity.name}`}
            >
              Discover
            </Button>
            <Button
              variant="contained"
              color="primary"
              data-testid="activity-drawer-primary-button"
              onClick={handleAddToPlan}
              className="add-to-plan-button"
              disabled={actionButtonDisabled}
            >
              {actionButtonText}
            </Button>
          </Box>
        </Box>
      </motion.div>
    </SwipeableDrawer>
  );
}

export default ActivityDrawer;
