import React from 'react';
import { motion } from 'motion/react';
import './FilterPanel.scss';
import Slider from '@mui/material/Slider';
import { Box, IconButton, Typography } from '@mui/material';
import { ExpandLess } from '@mui/icons-material';

export interface FilterOptions {
  activityTypes: string[];
  priceRange: [number, number];
  ambiance: string[];
  distance: number;
};

interface FilterPanelProps {
  filters: FilterOptions;
  onChange: (filters: any) => void;
  isOpen: boolean;
  onToggle: () => void;
}

export const ActivityTypes: { [key: string]: string } = {
  All: 'all',
  Attraction: 'attraction',
  Bar: 'bar',
  Cafe: 'cafe',
  Club: 'night_club',
  Entertainment: 'entertainment',
  Museum: 'museum',
  Park: 'park',
  Restaurant: 'restaurant',
  Shopping: 'shopping',
}

export const Ambiances: { [key: string]: string } = {
  Casual: 'casual',
  Upscale: 'upscale',
  Romantic: 'romantic',
  FamilyFriendly: 'family-friendly',
  Trendy: 'trendy',
  Quiet: 'quiet'
}


export const FilterPanel: React.FC<FilterPanelProps> = ({ filters, onChange, isOpen, onToggle }) => {
  const toggleFilter = (type: string, value: string) => {
    const currentFilters = [...(filters[type as keyof FilterOptions] as string[])];
    const index = currentFilters.indexOf(value);

    if (index === -1) {
      currentFilters.push(value);
    } else {
      currentFilters.splice(index, 1);
    }

    onChange({
      ...filters,
      [type]: currentFilters,
    });
  };

  return (
    <>
      {!isOpen && (
        <button data-testid="filter-button" className="filter-button" onClick={onToggle}>
          Filters
        </button>
      )}

      {isOpen && (
        <motion.div
          className="filter-container"
          initial={{ opacity: 0, x: 300 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 300 }}
        >
          <Box className="filter-header">
            <Typography variant="h6" className="title">Activity Type</Typography>
            <IconButton size="small" color="primary" onClick={onToggle}>
              <ExpandLess />
            </IconButton>
          </Box>
          <Box className="chip-container">
            {Object.keys(ActivityTypes).map(key => (
              <button
                key={key}
                className={`chip ${filters.activityTypes.includes(ActivityTypes[key]) ? 'active' : ''}`}
                onClick={() => toggleFilter('activityTypes', ActivityTypes[key])}
              >
                {key}
              </button>
            ))}
          </Box>

          <Box className="filter-header">
            <Typography variant="h6" className="title">Price Range</Typography>
          </Box>
          <Box className="slider-container">
            <Slider
              min={0}
              max={4}
              getAriaLabel={() => 'Price Range'}
              value={filters.priceRange}
              onChange={(_e, newPriceRange) => onChange({
                ...filters,
                priceRange: newPriceRange,
              })}
            />
            <div className="range-indicator">
              <span>{'$'.repeat(filters.priceRange[0]) || 'Free'}</span>
              <span>{'$'.repeat(filters.priceRange[1])}</span>
            </div>
          </Box>

          {/* TODO: Implement ambiance filter on backend, then uncomment this */}
          {/* <Box className="filter-header">
            <Typography variant="h6" className="title">Ambiance</Typography>
          </Box>
          <Box className="chip-container">
            {Object.keys(Ambiances).map(key => (
              <button
                key={key}
                className={`chip ${filters.ambiance.includes(Ambiances[key]) ? 'active' : ''}`}
                onClick={() => toggleFilter('ambiance', Ambiances[key])}
              >
                {key}
              </button>
            ))}
          </Box> */}

          <Box className="filter-header">
            <Typography variant="h6" className="title">Distance (km)</Typography>
          </Box>
          <Box className="slider-container">
            <Slider
              min={1}
              max={10}
              getAriaLabel={() => 'Price Range'}
              value={filters.distance / 1000}
              onChange={(_e, newPriceRange) => onChange({
                ...filters,
                distance: (typeof (newPriceRange) === 'number' ? newPriceRange : newPriceRange[0]) * 1000,
              })}
            />
            <div className="range-indicator">
              <span>1 km</span>
              <span>{filters.distance / 1000} km</span>
            </div>
          </Box>
        </motion.div>
      )}
    </>
  );
};
