import { VisibilityOff, Visibility } from "@mui/icons-material"
import { Grid, TextField, InputAdornment, IconButton, Button, Card, CardContent } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { AuthContext } from "src/hooks/AuthProvider"
import { useNavigate } from "react-router-dom"
import { isValidEmail, isValidPassword } from "src/lib/utils"
import BrandNameAnimation from "../CanvasAnimations/BrandNameAnimation"

export const Register: React.FC = () => {
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVerify, setPasswordVerify] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordVerify, setShowPasswordVerify] = useState(false);
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const isValid = isValidEmail(email) &&
      isValidPassword(password) &&
      passwordVerify === password &&
      firstName.length > 2 &&
      lastName.length > 2

    setSubmitDisabled(!isValid)
  }, [email, password, passwordVerify, firstName, lastName])

  const handleLoginFormChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, formPart: string) => {
    let isValid = false;

    switch (formPart) {
      case 'email': {
        setEmail(event.target.value)
        break;
      }
      case 'password': {
        setPassword(event.target.value)
        break;
      }
      case 'passwordVerify': {
        setPasswordVerify(event.target.value)
        break;
      }
      case 'lastName': {
        setLastName(event.target.value)
        break;
      }
      case 'firstName': {
        setFirstName(event.target.value)
        break;
      }
    }

    setSubmitDisabled(!isValid)
  }

  const handleRegistrationSubmitClick = () => {
    setSubmitDisabled(true);
    auth.handleRegistrationSubmit(email, password, firstName, lastName).then(() => {
      navigate("/login")
    }).catch((error) => {
      console.error("Login failed:", error);
    })
  };

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardContent>
        <BrandNameAnimation />
        <Grid container padding={'12px;'} spacing={3}>
          <Grid item xs={12}>
            <TextField required fullWidth label="Email" value={email} onChange={(e) => handleLoginFormChange(e, 'email')} data-testid="login-email-input" />
          </Grid>
          <Grid item xs={12}>
            <TextField required fullWidth label="First Name" value={firstName} onChange={(e) => handleLoginFormChange(e, 'firstName')} data-testid="login-fname-input" />
          </Grid>
          <Grid item xs={12}>
            <TextField required fullWidth label="Last Name" value={lastName} onChange={(e) => handleLoginFormChange(e, 'lastName')} data-testid="login-lname-input" />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Password" variant="outlined" fullWidth required
              value={password}
              type={showPassword ? "text" : "password"}
              onChange={(e) => handleLoginFormChange(e, 'password')}
              inputProps={{ "data-testid": "login-password-input" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword((prev) => !prev)} onMouseDown={(event: React.MouseEvent) => event.preventDefault()} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Verify Password" variant="outlined" fullWidth required
              value={passwordVerify}
              type={showPasswordVerify ? "text" : "password"}
              onChange={(e) => handleLoginFormChange(e, 'passwordVerify')}
              inputProps={{ "data-testid": "login-passwordverify-input" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPasswordVerify((prev) => !prev)} onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs><Button onClick={() => navigate('/login')}>Back</Button></Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs><Button variant="contained" disabled={submitDisabled} onClick={() => handleRegistrationSubmitClick()}>Submit</Button></Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
