import React, { useState } from 'react';
import { Box, IconButton, Typography, TextField } from '@mui/material';
import { Edit, Check, Clear, ExpandLess } from '@mui/icons-material';
import { usePlan } from 'src/hooks/PlanProvider';

import './PlanStepperHeader.scss';
import { isDraftPlan } from 'src/types/interfaces';

interface PlanStepperHeaderProps {
  collapseHandler: () => void;
}

const PlanStepperHeader: React.FC<PlanStepperHeaderProps> = ({ collapseHandler }) => {
  const [isEditing, setIsEditing] = useState(false);
  const { selectedPlan, setSelectedPlan } = usePlan();
  const [localPlanName, setLocalPlanName] = useState(selectedPlan.name);

  const handleEditClick = (): void => {
    setLocalPlanName(selectedPlan.name);
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setLocalPlanName(selectedPlan.name);
    setIsEditing(false);
  };

  const handleSaveClick = () => {
    setSelectedPlan({ ...selectedPlan, name: localPlanName.trim() });
    setIsEditing(false);
  };

  return (
    <Box className="plan-stepper-header" display="flex" alignItems="center">
      {isEditing ? (
        <>
          <IconButton size="medium" onClick={handleCancelClick} data-testid="plan-stepper-name-clear">
            <Clear className="mini-button-icon" />
          </IconButton>
          <TextField
            color="secondary"
            size="small"
            variant="outlined"
            value={localPlanName}
            inputProps={{ "data-testid": 'plan-stepper-name-input' }}
            onChange={(e) => setLocalPlanName(e.target.value)}
            sx={{ input: { color: '#00ffff' } }}
          />
          <IconButton size="medium" onClick={handleSaveClick} data-testid="plan-stepper-name-save">
            <Check className="mini-button-icon-primary" />
          </IconButton>
        </>
      ) : (
        <>
          <IconButton size="medium" onClick={handleEditClick} data-testid="plan-stepper-name-edit">
            <Edit className="mini-button-icon" />
          </IconButton>
          <Typography variant="h6" className="title" style={{ marginLeft: 8 }} data-testid="plan-stepper-name">
            {selectedPlan.name}{isDraftPlan(selectedPlan) && ' (Draft)'}
          </Typography>
        </>
      )}
      <Box className="button-group">
        <IconButton size="small" className="button" onClick={collapseHandler}>
          <ExpandLess />
        </IconButton>
      </Box>
    </Box>
  );
};

export default PlanStepperHeader;
