import { useSortable } from "@dnd-kit/sortable";
import { Delete, InfoOutlined } from "@mui/icons-material";
import { Box, Typography, IconButton } from "@mui/material";
import { Activity } from "src/types/interfaces";
import { CSS } from '@dnd-kit/utilities';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import './SortableActivity.scss';

interface SortableActivityProps {
  activity: Activity;
  index: number;
  onRemove: (activity: Activity) => void;
  onClick: (activity: Activity) => void;
}

export const SortableActivity: React.FC<SortableActivityProps> = ({ activity, index, onRemove, onClick }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: activity.placeId });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  return (
    <Box className="activity-container" ref={setNodeRef} style={style} {...attributes} {...listeners} data-testid={`sort-activity-${activity.placeId}`}>
      <Box><DragHandleIcon className="drag-handle" /></Box>
      <Box className="activity-details">
        <Typography noWrap={true} variant="body1">{activity.name}</Typography>
        <Typography noWrap={true} variant="body2" color="text.secondary">{activity.vicinity}</Typography>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box className="button-group">
        <IconButton className="delete-button-icon" size="small" color="error" onClick={() => onClick(activity)} data-testid={`sort-activity-info-${activity.placeId}`}>
          <InfoOutlined />
        </IconButton>
      </Box>
      <Box className="button-group">
        <IconButton className="delete-button-icon" size="small" color="error" onClick={() => onRemove(activity)} data-testid={`sort-activity-delete-${activity.placeId}`}>
          <Delete />
        </IconButton>
      </Box>
    </Box>
  );
}
