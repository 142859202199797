import { FC, useState } from 'react';
import { Modal, TextField, Button, Grid, Typography, Card, CardActions, CardContent, CardHeader, CardMedia, Box, Checkbox } from '@mui/material';
import { APIClient, APIEndpoint } from '../../lib/api_client';
import { IntegrationModalProps } from './integrations';
import { useQueryClient } from '@tanstack/react-query';
import { isValidEmail, isValidPhone, isValidSMSCode, normalizePhone } from '../../lib/utils';
import './Integrations.scss';
import { ConnectionState } from 'src/types/interfaces';


/**
 * A functional component that renders a modal for connecting or disconnecting a Resy account.
 */
const ResyModal: FC<IntegrationModalProps> = ({ open, connectionState, onClose }) => {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [phone, setPhone] = useState("");
  const [smsCodeSent, setSMSCodeSent] = useState(false);
  const [submitPreconnectDisabled, setSubmitPreconnectDisabled] = useState(true);
  const [submitConnectDisabled, setSubmitConnectDisabled] = useState(true);
  const [disconnectConsent, setDisconnectConsent] = useState(false);

  const deviceToken = crypto.randomUUID();
  const queryClient = useQueryClient();

  const handlePreconnect = async () => {
    console.debug("Handling Resy preconnection")
    const cleanPhone = normalizePhone(phone);
    try {
      let response = await APIClient.post(`${APIEndpoint}/services/resy/preconnect/`, { mobile_number: cleanPhone });
      if (response?.data?.connected) {
        onCloseModal()
      } else if (response.status === 200) {
        setSMSCodeSent(true);
      }
    } catch (error) {
      console.error("Failed to preconnect to Resy", error);
    }
  }

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, formPart: string) => {
    let isValidPreconnect = false;
    let isValidConnect = false;

    switch (formPart) {
      case 'email': {
        setEmail(event.target.value)
        isValidPreconnect = isValidEmail(event.target.value) && isValidPhone(phone)
        break;
      }
      case 'phone': {
        setPhone(event.target.value)
        isValidPreconnect = isValidPhone(event.target.value) && isValidEmail(email)
        break;
      }
      case 'code': {
        setCode(event.target.value)
        console.log(event.target.value)
        isValidConnect = isValidSMSCode(event.target.value)
        console.log(isValidConnect)
        break;
      }
    }

    setSubmitPreconnectDisabled(!isValidPreconnect)
    setSubmitConnectDisabled(!isValidConnect)
  }

  const handleConnect = async () => {
    console.debug("Handling Resy connection")
    const cleanPhone = normalizePhone(phone);
    await APIClient.post(`${APIEndpoint}/services/resy/connect/`,
      { mobile_number: cleanPhone, code: code, email: email, device_token: deviceToken }
    );
    setEmail("")
    setCode("")
    setPhone("")
    setSubmitPreconnectDisabled(true)
    setSubmitConnectDisabled(true)
    setSMSCodeSent(false);
    onCloseModal();
  }

  const handleDisconnect = async () => {
    console.debug("Handling Resy disconnection")
    await APIClient.post(`${APIEndpoint}/services/resy/disconnect/`);
    onCloseModal();
  }

  const onCloseModal = async () => {
    await queryClient.invalidateQueries({ queryKey: ['resyConnection'] });
    setDisconnectConsent(false)
    onClose()
  }

  return (
    <Modal open={open} onClose={onCloseModal} className='resy-modal'>
      <Card className='resy-card'>
        <Box className='resy-modal-title'>
          <CardMedia component="img" image={require('../../assets/resy-logo.png')} alt="Mindara Banner" style={{ width: 40, height: 40 }} />
          <CardHeader title={connectionState === ConnectionState.Connected ? "Disconnect Resy" : "Connect Resy"} />
        </Box>
        {connectionState === ConnectionState.Connected ? (
          <>
            <CardContent>
              <Typography variant="body1" color="text.primary"> Before disconnecting, please note:</Typography><br />
              <Typography variant="body2" color="text.secondary">{'\u2022'} New bookings will no longer be possible via Resy</Typography>
              <Typography variant="body2" color="text.secondary">{'\u2022'} Past booking details will no longer be viewable</Typography>
              <Typography variant="body2" color="text.secondary">{'\u2022'} Existing bookings will need to be managed via Resy</Typography><br />
              <Typography variant="body2" color="text.secondary"><Checkbox value={disconnectConsent} onChange={(e) => setDisconnectConsent(e.target.checked)} />I understand these consequences</Typography>
            </CardContent>
            <CardActions disableSpacing className='resy-card-actions'>
              <Grid container padding={'12px;'} spacing={3}>
                <Grid item xs><Button disabled={!disconnectConsent} onClick={() => handleDisconnect()} variant="contained" color='secondary'>Confirm</Button></Grid>
                <Grid item xs><Button onClick={() => onCloseModal()} variant="contained" color='primary'>Cancel</Button></Grid>
              </Grid>
            </CardActions>
          </>
        ) : smsCodeSent ? (
          <>
            <CardContent>
              <Typography variant="body2" color="text.secondary">An SMS code has been sent to your device. Enter the 6-digit code below:</Typography><br />
              <Grid item xs={12}><TextField required label="SMS Code" onChange={(e) => handleFormChange(e, "code")} value={code} placeholder="123456" data-testid="resy-code-input" /></Grid>
            </CardContent>
            <CardActions disableSpacing className='resy-card-actions'>
              <Grid container padding={'12px;'} spacing={3}>
                <Grid item xs><Button onClick={() => onCloseModal()}>Cancel</Button></Grid>
                <Grid item xs><Button variant="contained" disabled={submitConnectDisabled} onClick={() => handleConnect()}>Submit</Button></Grid>
              </Grid>
            </CardActions>
          </>
        ) : (
          <>
            <CardContent>
              <Typography variant="body2" color="text.secondary">Enter your Resy information below.</Typography><br />
              <Grid item xs={12}><TextField required label="Phone" onChange={(e) => handleFormChange(e, "phone")} value={phone} placeholder="(510) 123-4567" data-testid="resy-phone-input" /></Grid><br />
              <Grid item xs={12}><TextField required label="Email" onChange={(e) => handleFormChange(e, "email")} value={email} data-testid="resy-email-input" /></Grid>
            </CardContent>
            <CardActions disableSpacing className='resy-card-actions'>
              <Grid container padding={'12px;'} spacing={3}>
                <Grid item xs><Button onClick={() => onCloseModal()}>Cancel</Button></Grid>
                <Grid item xs><Button variant="contained" disabled={submitPreconnectDisabled} onClick={() => handlePreconnect()}>Submit</Button></Grid>
              </Grid>
            </CardActions>
          </>
        )}
      </Card>
    </Modal>
  )
};

export default ResyModal;
