import React, { useState, useRef, useEffect } from 'react';
import { Box, Card, CardMedia, IconButton, Typography } from '@mui/material';
import { Favorite, BookmarkBorder, Edit, Send } from '@mui/icons-material';
import './Inspiration.scss';
import { APIClient, APIEndpoint } from 'src/lib/api_client';
import { useGeolocation } from 'src/hooks/GeolocationProvider';
import { CustomPlace } from 'src/types/interfaces';


interface ReelCardProps {
  place: CustomPlace;
}

const SWIPE_THRESHOLD = 65;

const ReelCard: React.FC<ReelCardProps> = ({ place }) => {
  const [imageIndex, setImageIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const touchStartX = useRef<number>(0);
  const touchStartY = useRef<number>(0);
  const isScrollingRef = useRef<boolean | null>(null);
  const hasSwipedRef = useRef<boolean>(false);

  const handleTouchStart = (e: React.TouchEvent) => {
    touchStartX.current = e.touches[0].clientX;
    touchStartY.current = e.touches[0].clientY;
    isScrollingRef.current = null;
    hasSwipedRef.current = false;
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!containerRef.current || hasSwipedRef.current) return;

    const currentX = e.touches[0].clientX;
    const currentY = e.touches[0].clientY;
    const diffX = touchStartX.current - currentX;
    const diffY = touchStartY.current - currentY;

    // Determine scroll direction on initial movement
    if (isScrollingRef.current === null) {
      isScrollingRef.current = Math.abs(diffY) > Math.abs(diffX);

      // Allow vertical scrolling
      if (isScrollingRef.current) return;
    }

    // Handle horizontal swipe
    if (!isScrollingRef.current) {
      e.preventDefault(); // Prevent vertical scroll during horizontal swipe



      if (Math.abs(diffX) > SWIPE_THRESHOLD && !hasSwipedRef.current) {
        if (diffX > 0 && place.photos && imageIndex < place.photos.length - 1) {
          setImageIndex(prev => prev + 1);
          hasSwipedRef.current = true; // Prevent multiple swipes
        } else if (diffX < 0 && imageIndex > 0) {
          setImageIndex(prev => prev - 1);
          hasSwipedRef.current = true;
        }
      }
    }
  };

  const handleTouchEnd = () => {
    isScrollingRef.current = null;
    hasSwipedRef.current = false;
  };

  // Handle mouse drag for desktop
  const isDraggingRef = useRef<boolean>(false);
  const dragStartX = useRef<number>(0);

  const handleMouseDown = (e: React.MouseEvent) => {
    isDraggingRef.current = true;
    dragStartX.current = e.clientX;
    hasSwipedRef.current = false;

    // Prevent text selection during drag
    e.preventDefault();
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDraggingRef.current || hasSwipedRef.current) return;

    const diffX = dragStartX.current - e.clientX;

    if (Math.abs(diffX) > SWIPE_THRESHOLD) {
      if (diffX > 0 && place.photos && imageIndex < place.photos.length - 1) {
        setImageIndex(prev => prev + 1);
        hasSwipedRef.current = true;
      } else if (diffX < 0 && imageIndex > 0) {
        setImageIndex(prev => prev - 1);
        hasSwipedRef.current = true;
      }
    }
  };

  const handleMouseUp = () => {
    isDraggingRef.current = false;
    hasSwipedRef.current = false;
  };

  const handleMouseLeave = () => {
    isDraggingRef.current = false;
    hasSwipedRef.current = false;
  };

  return (
    <div className="reel-card-wrapper">
      <Card className="reel-card">
        <div
          ref={containerRef}
          className="reel-images-container"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseLeave}
        >
          <div className="reel-images-track" style={{ transform: `translateX(-${imageIndex * 100}%)` }}>
            {(place?.photos || []).map((photo, idx) => (
              <CardMedia
                key={idx}
                component="img"
                className="reel-image"
                image={`https://maps.googleapis.com/maps/api/place/photo?maxheight=${photo.height || "250"}&maxwidth=${photo.width}&photoReference=${photo.photoReference}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
                alt={`Slide ${idx + 1}`}
              />
            ))}
          </div>

          <div className="carousel-dots">
            {(place?.photos || []).map((_, idx) => (<div key={idx} className={`dot ${idx === imageIndex ? 'active' : ''}`} />))}
          </div>

          <div className="content-overlay">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" gap={1}>
                <IconButton className="action-button"><Favorite /></IconButton>
                <IconButton className="action-button"><Edit /></IconButton>
                <IconButton className="action-button"> <Send /></IconButton>
              </Box>
              <IconButton className="action-button"><BookmarkBorder /></IconButton>
            </Box>

            <div className="reel-content">
              <Typography variant="subtitle1" className="username">
                {place.name}
              </Typography>
              <Typography variant="body2" className="caption">
                This is features a [beginning], [middle], and [end] over [x] hours.<br />
                2 people, $36 ea.
              </Typography>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

const Inspiration: React.FC = () => {
  const { userGeolocation } = useGeolocation();
  const [ places, setPlaces ] = useState<CustomPlace[]>([]);

  useEffect(() => {
    const fetchMarkers = async () => {
      const response = await APIClient.get(`${APIEndpoint}/markers/`, {
        headers: { Accept: 'application/json' },
        params: userGeolocation
      });
      setPlaces(response.data.slice(0, 3) || []);
    };

    fetchMarkers();
  }, [userGeolocation]);

  return (
    <div className="inspiration-container">
      <div className="reels-scroll-container">
        {places.map((place) => (
          <ReelCard
            place={place}
          />
        ))}
      </div>
    </div>
  );
};

export default Inspiration;
